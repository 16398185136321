import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import HorizontalInput from '../HorizontalInput.jsx';

const Order = ({ reference, selectedStoreRef, storeRefs, onGenerateReference }) => (
  <FormSection title="Pedido">
    <HorizontalInput label="Orden de trabajo">
    <div className="row">
      <div className="col-md-8 pr-1 mb-0">
        <input type="text" className="form-control" id="order_order_ref_1" name="order[order_ref_1]" placeholder="Orden de trabajo"
          defaultValue={reference}
          onChange={(e)=> reference = e.target.value}/>
      </div>
      <div className="col-md-4">
        <button type="button" className="form-control btn btn-primary" onClick={() => onGenerateReference() }>Generar ref</button>
      </div>

    </div>
    </HorizontalInput>

    {storeRefs.length > 0 && <StoreRefsSelector storeRefs={storeRefs} selectedStoreRef={selectedStoreRef} />}
  </FormSection>
);

const StoreRefsSelector = ({ storeRefs, selectedStoreRef }) => {
  if (storeRefs.length === 1) {
    return (
      <input
        className="form-control"
        name="order[store_ref]"
        defaultValue={storeRefs[0]}
        id="order_store_ref"
        hidden={true} />
    );
  }

  return (
    <HorizontalInput name="order[store_ref]" label="Código de tienda">
      <select
        className="form-control"
        name="order[store_ref]"
        defaultValue={selectedStoreRef}
        id="order_store_ref"
      >
        {storeRefs.map(ref => <option key={ref} defaultValue={ref}>{ref}</option>)}
      </select>
    </HorizontalInput>
  );
};

Order.propTypes = {
  reference: PropTypes.string,
  selectedStoreRef: PropTypes.string,
  storeRefs: PropTypes.arrayOf(PropTypes.string),
  onGenerateReference: PropTypes.func,
};

Order.defaultProps = {
  reference: '',
  selectedStoreRef: '',
  storeRefs: [],
};

export default Order;
