const messages = {
  "es-ES": {
    nin: "NIF/NIE/CIF",
    total: "Total",
    unavailablePm: "No hay métodos de pago disponibles",
    returnToPm: "Volver a métodos de pago",
    instalments: "cuotas",
    month: "mes",
    continue: "Continuar"
  },
  "es-CO": {
    nin: "Cédula de ciudadania",
    total: "Total",
    unavailablePm: "No hay métodos de pago disponibles",
    returnToPm: "Volver a métodos de pago",
    instalments: "cuotas",
    month: "mes",
    continue: "Continuar"
  },
  "es-PE": {
    nin: "Código Único de Identificación",
    total: "Total",
    unavailablePm: "No hay métodos de pago disponibles",
    returnToPm: "Volver a métodos de pago",
    instalments: "cuotas",
    month: "mes",
    continue: "Continuar"
  },
  "fr-FR": {
    nin: "Carte d'identité",
    total: "Totale",
    unavailablePm: "Aucun moyen de paiement disponibles",
    returnToPm: "Revenir aux paiements",
    instalments: "versements",
    month: "mois",
    continue: "Continuer"
  },
  "it-IT": {
    nin: "Codice fiscale",
    total: "Totale",
    unavailablePm: "Non ci sono altri metodi di pagamento disponibili",
    returnToPm: "Ritornare ai metodi di pagamento",
    instalments: "quote",
    month: "mese",
    continue: "Continua"
  },
  "pt-PT": {
    nin: "Número de identificación fiscal",
    total: "Total",
    unavailablePm: "Sem formas de pagamento disponíveis",
    returnToPm: "Retornar a pagamentos",
    instalments: "parcelas",
    month: "mês",
    continue: "Prosseguir"
  },
};

export default messages;
