import React, { useState, useEffect } from 'react';
import Form from './Form';
import { IntlProvider, useIntl } from 'react-intl';
import messages from '../messages.js';

const LOCALE_BY_COUNTRY = {
  "ES": "es-ES",
  "CO": "es-CO",
  "FR": "fr-FR",
  "IT": "it-IT",
  "PE": "es-PE",
  "PT": "pt-PT",
};

const CheckoutComponent = ({ destURL, orderUUID, cancelURL, totalPrice, shopName, paymentOptions }) => {

  const intl = useIntl();
  const [selectedMethod, setSelectedMethod] = useState('');
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);
  const [creditAgreements, setCreditAgreements] = useState([]);

  // Saving the selected payment method in the state
  const handleSelect = (e) => setSelectedMethod( e.target.value );
  const handleCreditAgreement = () => setCreditAgreements( window.Sequra.computeCreditAgreements({amount: totalPrice.toString()}));

  useEffect(() => {
    window.Sequra.onLoad(handleCreditAgreement);
    paymentOptions.filter(p => p.length !== 0).map(po => {
      return po.methods.map(pm => {
        return setAvailablePaymentMethods([...availablePaymentMethods, pm.product]);
      });
    });
  }, []);

  return (
    <div className="checkout-wrapper">
      <div className="checkout-container">
        <div className="logo-wrapper">
          <img
            width="100%"
            height="100%"
            alt="SeQura Logo"
            src="https://live.sequracdn.com/assets/images/logos/logo.svg"
          />
        </div>

        <div className="summary">
          <div id="shop-name">{shopName.toUpperCase()}</div>
          <div className="total-flex">
            <div className="total">{intl.formatMessage({ id: 'total', defaultMessage: "Total"})}</div>  
            <div className="total-price">{(totalPrice / 100).toFixed(2)} €</div>
          </div>
        </div>

        {/* Shows if no payment methods available */}
        { (availablePaymentMethods.length === 0) &&
          <>
            <div className="unavailable-message">{intl.formatMessage({ id: 'unavailablePm', defaultMessage: "No hay métodos de pago disponibles"})}</div>
            <a className="back-link" href={cancelURL}>{intl.formatMessage({ id: 'unavailablePm', defaultMessage: "Volver a métodos de pago"})}</a>
          </>
        }

        {/* Shows if payment methods available */}
        { (availablePaymentMethods.length !== 0) &&
            <Form 
              paymentOptions={paymentOptions} 
              destURL={destURL} 
              orderUUID={orderUUID} 
              selectedMethod={selectedMethod}
              cancelURL={cancelURL}
              handleSelect={handleSelect}
              creditAgreements={creditAgreements}
            />
        }

      </div>  
    </div>
  )
}

const Checkout = ({...props}) => {
  const locale = LOCALE_BY_COUNTRY[props.locale.toUpperCase()] || 'es-ES';
  return(
    <IntlProvider locale={locale} messages={messages[locale]}>
      <CheckoutComponent {...props} />
    </IntlProvider>
  );
}

export default Checkout;