import React, { Component } from 'react';
import MerchantSelect from '../MerchantSelect';

class UserForm extends Component {
  constructor(props) {
    super(props)
    this.merchants = props.merchants
    this.selectedMerchant = props.selectedMerchant
    this.roles = props.roles
    this.authenticityToken = props.authenticityToken
    this.state = { merchantType: this.selectedMerchant.type }
  }

  setMerchant(reference) {
    this.props.merchantHasChanged(reference)
  }



  render() {
    return (
      <div className="single-user-form">
        <form className="col-4 mb-3 pl-0" action="/users" method="post">
          <input type="hidden" name="authenticity_token" value={this.authenticityToken} />
          <div className="form-group">
            <label className="mb-0">Email</label>
            <input type="email" className="form-control" name="users[email]" placeholder="Email" autoComplete="off" required/>
          </div>
          <div className="form-group">
            <label className="mb-0">Password</label>
            <input type="password" className="form-control" name="users[password]" placeholder="password" autoComplete="new-password" required/>
          </div>
          {(this.props.selectedMerchant.type == "products" || this.props.selectedMerchant.type == "garages") &&
          <div className="form-group">
            <label className="mb-0">Store Refs</label>
            <input type="text" className="form-control" name="users[store_refs]" placeholder="sequra_store_1, sequra_store_2"/>
            <p className="font-italic small">Solo para puntos de venta cara al público. Déjalo en blanco si es un call center</p>
          </div>
          }
          <div className="form-group">
            <label className="mb-0">Role</label>
            <select className="mr-2 form-control" name="users[role]" id="users_role">
              {
                this.roles.map((role, key) => { return <option key={key} value={role}>{role}</option> })
              }
            </select>
          </div>
          <div className="form-group">
            <label className="mb-0">Merchant</label>
            <MerchantSelect merchants={this.merchants}
              onChange={e => this.setMerchant(e.target.value) }
              defaultValue={this.selectedMerchant.reference}
              className="mr-2 form-control"
              name="users[merchant]"
              id="users_merchant"
            />
          </div>
          <div className="form-group">
            <input type="submit" name="commit" value="Guardar" className="btn btn-primary" data-disable-with="Guardar Users" />
          </div>
        </form>
      </div>
    )
  }
}

export default UserForm;
