import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import HorizontalInput from '../HorizontalInput.jsx';
import ExtraNinInfo from './ExtraNinInfo.jsx';
import { useIntl } from 'react-intl';

const Shopper = ({ givenNames, surnames, email, dateOfBirth, requiresExtraNinInfo, nin, ninControl }) => {
  const intl = useIntl();
  return (
    <FormSection title="Cliente" id="shopper">
      <HorizontalInput
        name="order[given_names]"
        label="Nombre"
        defaultValue={givenNames} />
      <HorizontalInput
        name="order[surnames]"
        label="Apellidos"
        defaultValue={surnames} />
      <HorizontalInput
        name="order[email_address]"
        label="Email"
        defaultValue={email} />
      <HorizontalInput label="Fecha de nacimiento">
        <div className="row">
          <div className="form-group col-3 col-md-2 pr-1 mb-0">
            <select className="form-control" name="order[date_of_birth][day]" id="order_date_of_birth_day" defaultValue={dateOfBirth.day}>
              <option disabled>Día</option>
              {dayOptions()}
            </select>
          </div>
          <div className="form-group col pl-0 pr-1 mb-0">
            <select className="form-control" name="order[date_of_birth][month]" id="order_date_of_birth_month" defaultValue={dateOfBirth.month}>
              <option disabled>Mes</option>
              {monthOptions()}
            </select>
          </div>
          <div className="form-group col pl-0 mb-0">
            <select className="form-control" name="order[date_of_birth][year]" id="order_date_of_birth_year" defaultValue={dateOfBirth.year}>
              <option disabled>Año</option>
              {yearOptions()}
            </select>
          </div>
        </div>
      </HorizontalInput>
      <HorizontalInput
        name="order[nin]"
        label={intl.formatMessage({ id: "nin", defaultMessage: "National identification number" })}
        defaultValue={nin} />
      <ExtraNinInfo requiresExtraNinInfo={requiresExtraNinInfo} ninControl={ninControl} />
    </FormSection>
  );
}

const dayOptions = () => (new Array(31)).fill(0).map((_, index) => <option key={index + 1} value={index + 1}>{index + 1}</option>);

const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const monthOptions = () => MONTH_NAMES.map((month_name, index) => <option key={month_name} value={index + 1}>{month_name}</option>);

const ENDING_YEAR = (new Date).getFullYear() - 18;
const STARTING_YEAR = ENDING_YEAR - 80;

const yearOptions = () => (new Array(ENDING_YEAR - STARTING_YEAR)).fill(0).map((_, index) => <option key={index} value={ENDING_YEAR - index}>{ENDING_YEAR - index}</option>);

const ninControlTooltip = '<img src="/assets/dni-back.png" />';

Shopper.propTypes = {
  nin: PropTypes.string,
  givenNames: PropTypes.string,
  surnames: PropTypes.string,
  email: PropTypes.string,
  requiresExtraNinInfo: PropTypes.bool,
  ninControl: PropTypes.string,
  dateOfBirth: PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string,
  }),
};

Shopper.defaultProps = {
  nin: '',
  givenNames: '',
  surnames: '',
  email: '',
  dateOfBirth: {
    day: '',
    month: '',
    year: '',
  },
  requiresExtraNinInfo: false,
  ninControl: '',
};

export default Shopper;
