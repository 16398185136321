import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber} from 'react-intl';

const ItemRow = ({
  item,
  onTypeChanged,
  onNameChanged,
  onPriceChanged,
  onQuantiyChanged,
  onDateChanged,
  onItemRemoved,
  autocompleteList,
  readOnlyType,
  readOnlyQuantity,
  readOnlyPrice,
  canUseNonStoredServices,
  canSetEndingDate,
  canRemove,
  currency,
}) => {
  const { type, reference, name, quantity, price_with_tax } = item;
  const total = price_with_tax && quantity ? price_with_tax * quantity : 0;
  const baseName = 'order[items][]';
  return (
    <div className="row">
      <div className="form-group col pr-1">
        <select readOnly={readOnlyType} className="form-control" name={`${baseName}[type]`} value={type} onChange={event => onTypeChanged(item, event.target.value)}>
          {optionsForType(type)}
        </select>
      </div>
      {autocompleteList && !canUseNonStoredServices && <input name={`${baseName}[reference]`} type="hidden" value={reference} />}
      <div className="form-group col pl-0 pr-1">
        <input
          className="form-control"
          name={`${baseName}[name]`}
          list={autocompleteList ? autocompleteList : undefined}
          value={name}
          placeholder="Nombre"
          onChange={event => onNameChanged(item, event.target.value)}
        />
      </div>
      <div className="form-group col pl-0 pr-1">
        <input
          type="number"
          step="any"
          className="form-control"
          name={`${baseName}[price_with_tax]`}
          value={price_with_tax}
          placeholder="Precio"
          readOnly={readOnlyPrice}
          onChange={event => onPriceChanged(item, Number.parseFloat(event.target.value))}
          max={type === 'discount' ? 0 : undefined}
          min={type === 'discount' ? undefined : 0}
        />
      </div>
      <div className="form-group col pl-0">
        <input
          readOnly={readOnlyQuantity}
          type="number"
          step="1"
          className="form-control"
          name={`${baseName}[quantity]`}
          value={quantity}
          placeholder="Cantidad"
          onChange={event => onQuantiyChanged(item, Number.parseFloat(event.target.value))}
          min="0"
        />
      </div>
      {canSetEndingDate && type == "service" && <div className="form-group col pl-0">
        <input
          type="date"
          className="form-control"
          name={`${baseName}[ends_on]`}
          value={dateForItem(item)}
          placeholder="Date"
          onChange={event => onDateChanged(item, event.target.value)}
        />
      </div>}
      <div className="col-1">
        <span className="text-nowrap"> <FormattedNumber value={total} style="currency" currency={currency} /></span>
      </div>
      <div className="col-1">
        {canRemove && <input
          type="button"
          className="form-control btn btn-outline-primary"
          onClick={() => onItemRemoved(item)}
          value="&minus;"
        />}
      </div>
    </div>
  );
};

const optionsForType = type => {
  if (type === 'service') {
    return (<option value="service">Servicio</option>);
  }
  else if (type === 'registration') {
    return (<option value="registration">Matrícula</option>);
  }
  else {
    return (
      <React.Fragment>
        <option value="product">Producto</option>
        <option value="handling">Gastos de envío</option>
        <option value="discount">Descuento</option>
      </React.Fragment>
    );
  }
};

const dateForItem = ({ ends_on }) => {
  const oneYearFromNow = Date.now() + 365*24*60*60*1000;
  const date = new Date(ends_on || oneYearFromNow)
  return date.toISOString().replace(/T.*/, '');
}

ItemRow.propTypes = {
  item: PropTypes.object,
  onTypeChanged: PropTypes.func.isRequired,
  onNameChanged: PropTypes.func.isRequired,
  onPriceChanged: PropTypes.func.isRequired,
  onQuantiyChanged: PropTypes.func.isRequired,
  onDateChanged: PropTypes.func.isRequired,
  onItemRemoved: PropTypes.func.isRequired,
  autocompleteList: PropTypes.string,
  readOnlyType: PropTypes.bool,
  readOnlyQuantity: PropTypes.bool,
  readOnlyPrice: PropTypes.bool,
  canUseNonStoredServices: PropTypes.bool,
  canSetEndingDate: PropTypes.bool,
  canRemove: PropTypes.bool,
  currency: PropTypes.string,
};

ItemRow.defaultProps = {
  item: {},
  autocompleteList: undefined,
};

export default ItemRow;
