import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import HorizontalInput from '../HorizontalInput.jsx';

const AvailableInstalments = ({ availableInstalmentCounts, selectedInstalmentCounts }) => (
  <FormSection title="Número de cuotas">
    <HorizontalInput name="order[product_code]" label="Número de cuotas">
      {availableInstalmentsCheckboxes(availableInstalmentCounts, selectedInstalmentCounts)}
    </HorizontalInput>
  </FormSection>
);

const availableInstalmentsCheckboxes = (instalmentCounts, selectedInstalmentCounts) => instalmentCounts.map(cuotas => {
  const id = `order_available_instalments_count_${cuotas}`;
  return (
    <div key={id} className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        defaultChecked={selectedInstalmentCounts.length > 0 ? Object.values(selectedInstalmentCounts).includes(`${cuotas}`) : true}
        defaultValue={cuotas}
        name="order[available_instalments_count][]"
        id={id}
      />
      <label className="form-check-label" htmlFor={id}>
        {cuotas} cuotas
      </label>
    </div>
  );
});

AvailableInstalments.propTypes = {
  availableInstalmentCounts: PropTypes.array,
  selectedInstalmentCounts: PropTypes.array,
};

AvailableInstalments.defaultProps = {
  availableInstalmentCounts: [],
  selectedInstalmentCounts: [],
};

export default AvailableInstalments;
