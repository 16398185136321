import React from 'react';
import PropTypes from 'prop-types';

const MerchantSelect = ({ merchants, ...props }) => (
  <select {...props}>
    {merchantOptions(merchants)}
  </select>
);

const merchantOptions = merchants =>
  merchants.sort(compareMerchants).map(merchant =>
    <option key={merchant.reference} value={merchant.reference}>
      {merchant.name || merchant.reference}
    </option>
  );

const compareMerchants = (a, b) => {
  var nameA = (a.name || a.reference).toUpperCase();
  var nameB = (b.name || a.reference).toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export default MerchantSelect;

MerchantSelect.propTypes = {
  merchants: PropTypes.array.isRequired,
};
