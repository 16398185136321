import Axios from 'axios';

class CreditAgreement {
  constructor(merchant) {
    this.merchant = merchant;
    this.loadJsCosts = this.startLoadJsCosts();
  }

  startLoadJsCosts() {
    return Axios.get(this.merchant.costs_js_url)
      .then(response => response.data)
      .then(costs => window.eval(costs));
  }

  computeRemote(productCode, items) {
    if (!this.usesCustomCreditAgreements(productCode)) return Promise.reject("Does not use custom credit agreement");

    const params = {
      params: {
        merchant: this.merchant.reference,
        items: JSON.stringify(items),
        product: productCode,
      },
    }

    return Axios.get('/credit_agreements', params).then(response => response.data)
  }

  computeLocal(productCode, priceWithTax, registrationAmount, currencySymbol) {
    if (this.usesCustomCreditAgreements(productCode)) return Promise.reject("Uses custom credit agreements");

    return this.loadJsCosts.then(() => {
      Sequra.money.symbol = ' ' + currencySymbol;
      return Sequra.loan.compute({ total_with_tax: (priceWithTax - registrationAmount)*100 });
    });
  }

  availableInstalmentCounts(productCode) {
    return this.loadJsCosts.then(() => {
      const productConfig = Sequra.loan.products_config[productCode];

      return (productConfig && productConfig.instalment_counts) || [];
    });
  }

  usesCustomCreditAgreements(productCode) {
    return this.customMerchantOptions(productCode) || this.usesFullFeesInAdvance(productCode);
  }

  customMerchantOptions(productCode) {
    return productCode === 'pp9' && this.merchant.uses_custom_merchant_options
  }

  usesFullFeesInAdvance(productCode) {
    return productCode === 'pp3' && this.merchant.uses_full_fees_in_advance
  }
}

export default CreditAgreement;
