import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import HorizontalInput from '../HorizontalInput.jsx';

const PaymentMethod = ({ productCodes, selectedProductCode, onPaymentMethodSelected }) => (
  <FormSection title="Método de pago" id="payment_method">
    <HorizontalInput name="order[product_code]" label="Tipo de financiación">
      {paymentOptions(productCodes, selectedProductCode, onPaymentMethodSelected)}
    </HorizontalInput>
  </FormSection>
);

const paymentOptions = (productCodes, selectedProductCode, onPaymentMethodSelected) => productCodes.map((productCode, index) => {
  const id = `order_product_code_${productCode}`;
  return (
    <div key={productCode} className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name="order[product_code]"
        id={id}
        defaultChecked={selectedProductCode ? productCode === selectedProductCode : index === 0}
        defaultValue={productCode}
        onChange={() => onPaymentMethodSelected(productCode)}
      />
      <label className="form-check-label" htmlFor={id}>
        {productCodeLabels[productCode] || productCode}
      </label>
    </div>
  );
});

const productCodeLabels = {
  i1: 'Compra ahora, paga después',
  pp3: 'Plan de pago',
  pp5: 'Campaña',
  pp6: 'Plan de pago con cuotas a cargo de la tienda',
  pp9: 'Plan de pago mixto',
  sp1: 'Divide en 3',
};

PaymentMethod.propTypes = {
  productCodes: PropTypes.arrayOf(PropTypes.string),
  selectedProductCode: PropTypes.string,
  onPaymentMethodSelected: PropTypes.func,
};

PaymentMethod.defaultProps = {
  productCodes: [],
  selectedProductCode: '',
};

export default PaymentMethod;
