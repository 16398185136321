import React, { Component } from 'react';
import Axios from 'axios';

class UserList extends Component {
  headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
  state = { users: [] }

  constructor(props) {
    super(props)

    this.authenticityToken = props.authenticityToken
    this.merchant = props.merchant
    this.getUsers()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.merchant !== this.props.merchant) { this.getUsers(this.props.merchant); }
  }

  getUsers = (merchant = this.merchant) => {
    const params = { merchant: merchant.reference };
    const headers = this.headers
    Axios
      .get('/users/search', { params, headers, responseType: 'json' })
      .then(response => response.data)
      .then(users => this.setUsers(users));
  };

  onDelete = (user) => {
    const params = { merchant: this.props.merchant.reference, authenticity_token: this.authenticityToken, };
    const headers = this.headers
    Axios
      .delete(`/users/${user.id}`, { params, headers, responseType: 'json' })
      .then(() => this.getUsers())
      .catch(console.error);
  }

  setUsers(users) { this.setState({users: users}) }

  render() {
    return (
      <div className="user-list">
        <table className="table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.map(u => <UserRow key={u.email} user={u} onDelete={this.onDelete} />)}
          </tbody>
        </table>
      </div>
    )
  };
}

const UserRow = ({ user, onDelete }) => (
  <tr>
    <td>{user.email}</td>
    <td>{user.role}</td>
    <td><a className="btn btn-outline-secondary" onClick={() => onDelete(user)}>Borrar</a></td>
  </tr>
);


export default UserList;
