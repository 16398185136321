import React, { Component } from 'react';
import MerchantSelect from '../MerchantSelect';
import DatePicker from "react-datepicker";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('es', es)

class NewMerchantItemForm extends Component {
  constructor(props) {
    super(props)
    this.authenticityToken = props.authenticityToken
    this.merchants = props.merchants
    this.state = {
      merchant: this.merchants[0],
      endsOn: new Date(),
      firstChargeDateOn: new Date(),
      startDate: "",
      endingType: "ends_in",
      firstChargeDateType: "desired_first_charge_in",
      downloadable: this.merchants[0].type == "services"
    }
  }

  setMerchant(reference) {
    let selectedMerchant = this.merchants.find((merchant) => merchant.reference == reference)
    this.setState({merchant: selectedMerchant, downloadable: selectedMerchant.type == "services"})
  }

  handleDateChange(field, date) {
    this.setState({[field]: date})
  }

  get isServices() { return this.state.merchant.type == "services" }
  get usesEndsOn() { return this.state.endingType == "ends_on" && this.isServices }
  get usesEndsIn() { return this.state.endingType == "ends_in" && this.isServices }

  get canSetFirstChargeDate() { return this.state.merchant.can_set_first_charge_date }
  get usesFirstChargeDateOn() { return this.state.firstChargeDateType == "desired_first_charge_on" && this.canSetFirstChargeDate }
  get usesFirstChargeDateIn() { return this.state.firstChargeDateType == "desired_first_charge_in" && this.canSetFirstChargeDate }

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <form action="/merchant_items" method="post">
            <input type="hidden" name="authenticity_token" value={this.authenticityToken} />
            <div className="form-group">
              <label className="mb-0">Merchant</label>
              <MerchantSelect merchants={this.merchants} onChange={e => this.setMerchant(e.target.value) }
                defaultValue={this.state.merchant.reference} className="mr-2 form-control"
                name="merchant_item[merchant_ref]"
                id="merchant_item_merchant"
              />
            </div>
            <div className="form-group">
              <label className="mb-0">Reference</label>
              <input type="text" name="merchant_item[reference]" className="form-control" required />
            </div>
            <div className="form-group">
              <label className="mb-0">Name</label>
              <input type="text" name="merchant_item[name]" className="form-control" required />
            </div>
            <div className="form-group">
              <label className="mb-0">Precio con iva</label>
              <input type="number" step=".01" name="merchant_item[price_with_tax]" className="form-control" required />
              <p className="font-italic small">Precio con iva</p>
            </div>
            { this.isServices &&
            <div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="endingType" id="inlineRadio1"
                  value="ends_in"
                  onChange={e => this.setState({endingType: e.target.value})}
                  required
                  defaultChecked={this.usesEndsIn}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">Ends in</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="endingType" id="inlineRadio2"
                  value="ends_on"
                  onChange={e => this.setState({endingType: e.target.value})}
                  required />
                <label className="form-check-label" htmlFor="inlineRadio2">Ends on</label>
              </div>
              <p className="font-italic small">Para informar una duración selecciona “Ends In”. Para informar una fecha de fin del curso selecciona “Ends on”.</p>
            </div>
            }
            { this.usesEndsIn &&
              <div className="form-group">
                <label className="mb-0">Ends in</label>
                <input type="text" name="merchant_item[ends_in]" className="form-control" required />
                <p className="font-italic small">Duración del curso, en días (D), meses (M) o años (Y). Ejemplo: curso de 3 meses P3M</p>
              </div>
            }
            { this.usesEndsOn &&
              <div className="form-group">
                <label className="mb-0">Ends on</label>
                <div><DatePicker dateFormat="yyyy-MM-dd" locale="es" selected={this.state.endsOn} onChange={date => this.handleDateChange("endsOn", date)}/></div>
                <input type="hidden" name="merchant_item[ends_on]" className="form-control" value={this.state.endsOn} />
                <p className="font-italic small">Fecha de fin del curso</p>
              </div>
            }
            { this.canSetFirstChargeDate &&
            <div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="firstChargeDateType" id="inlineRadio1"
                  value="desired_first_charge_in"
                  onChange={e => this.setState({firstChargeDateType: e.target.value})}
                  required
                  defaultChecked={this.usesFirstChargeDateIn}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">Desired first charge in</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="firstChargeDateType" id="inlineRadio2"
                  value="desired_first_charge_on"
                  onChange={e => this.setState({firstChargeDateType: e.target.value})}
                  required />
                <label className="form-check-label" htmlFor="inlineRadio2">Desired first charge on</label>
              </div>
              <p className="font-italic small">Para establecer una fecha relativa "Desired first charge In”. Para establecer una fecha concreta “Desired first charge on”.</p>
            </div>
            }
            { this.usesFirstChargeDateIn &&
              <div className="form-group">
                <label className="mb-0">First charge in</label>
                <input type="text" name="merchant_item[desired_first_charge_in]" className="form-control" required />
                <p className="font-italic small">¿Cuándo se cargará la primera cuota a partir de la confirmación del pedido? En días (D), meses (M) o años (Y). Ejemplo: Después de 3 meses P3M</p>
              </div>
            }
            { this.usesFirstChargeDateOn &&
              <div className="form-group">
                <label className="mb-0">First charge on</label>
                <div><DatePicker dateFormat="yyyy-MM-dd" locale="es" selected={this.state.firstChargeDateOn} onChange={date => this.handleDateChange("firstChargeDateOn", date)}/></div>
                <input type="hidden" name="merchant_item[desired_first_charge_on]" className="form-control" value={this.state.firstChargeDateOn} />
                <p className="font-italic small">Fecha exacta en la que se cargará la primera cuota</p>
              </div>
            }
            <div className="form-group">
              <label className="mb-0">Fecha de inicio (opcional)</label>
              <div><DatePicker dateFormat="yyyy-MM-dd" locale="es" selected={this.state.startDate} onChange={date => this.handleDateChange("startDate", date)}/></div>
              <input type="hidden" name="merchant_item[start_date]" className="form-control" value={this.state.startDate} />
              <p className="font-italic small">Fecha en la que se empieza a ofrecer el curso</p>
            </div>
            <div className="form-check">
              <input type="checkbox" className="form-check-input" name="merchant_item[downloadable]" checked={this.state.downloadable} onChange={(e)=>this.setState({downloadable: e.target.checked}) }/>
              <label className="form-check-label">Descargable</label>
              <p className="font-italic small">Seleccionalo para cursos online</p>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default NewMerchantItemForm;
