import React from 'react';
import PropTypes from 'prop-types';
import FormSection from './FormSection.jsx';
import {FormattedNumber} from 'react-intl';

const Simulator = props => (
  <FormSection title="Simulador">
    {specificSimulator(props)}
  </FormSection>
);

const specificSimulator = ({ creditAgreement, productCode, ...props }) => {
  if (productCode === 'i1') return <I1 />;
  if (!creditAgreement.length) return undefined;
  if (productCode === 'pp3') return <Pp3 creditAgreement={creditAgreement} {...props} />;
  if (productCode === 'pp5') return <Pp5 creditAgreement={creditAgreement} {...props} />;
  if (productCode === 'pp6') return <Pp6 creditAgreement={creditAgreement} {...props} />;
  if (productCode === 'pp9') return <Pp9 creditAgreement={creditAgreement} {...props} />;
  if (productCode === 'sp1') return <Sp1 creditAgreement={creditAgreement} {...props} />;
  return undefined;
};

const I1 = () => (<span>Compra ahora, paga después</span>);

const PpBase = ({ creditAgreement, usesRegistration, registrationAmount, setupFee, currency, downpaymentFee = defaultDownpaymentFee }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Cuotas</th>
        <th>Comisión de apertura</th>
        <th>Coste Mensual</th>
        <th>Pagarás ahora</th>
        <th>Pagarás cada mes</th>
        <th>Coste total</th>
      </tr>
    </thead>
    <tbody>
      {creditAgreement.map(item =>
        <tr key={item.instalment_count}>
          <td>{item.instalment_count}</td>
          <td>
            <FormattedNumber
              value={downpaymentFee(item)}
              style="currency"
              currency={currency} />
          </td>
          <td>
            <FormattedNumber
              value={item.instalment_fee.value/100.0}
              style="currency"
              currency={currency} />
          </td>
          <td>
            <FormattedNumber
              value={initialPayment({ usesRegistration, registrationAmount, downPayment: item.down_payment_total.value/100.0, setupFee })}
              style="currency"
              currency={currency} />
          </td>
          <td>
            <FormattedNumber
              value={item.instalment_total.value/100.0}
              style="currency"
              currency={currency} />
          </td>
          <td>
            <FormattedNumber
              value={item.grand_total.value/100.0 + setupFee}
              style="currency"
              currency={currency} />
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

const defaultDownpaymentFee = item => item.setup_fee ? item.setup_fee.value/100.0 : 0;

const initialPayment = ({ usesRegistration, registrationAmount, downPayment, setupFee }) => {
  const base = withRegistration(usesRegistration, registrationAmount) ? registrationAmount : downPayment;
  return base + setupFee;
};
const withRegistration = (usesRegistration, registrationAmount) => usesRegistration && registrationAmount > 0;

const Pp3 = ({ usesFullFeesInAdvance, ...props }) => (
  <PpBase
    downpaymentFee={item => usesFullFeesInAdvance ? item.cost_of_credit.value/100.0 : item.setup_fee.value/100.0}
    {...props}
  />
);

const Pp5 = ({ creditAgreement, usesRegistration, registrationAmount, currency }) => {
  const agreement = creditAgreement[0];
  if (!agreement) return undefined;
  const due_date = new Date(agreement.due_date);
  const due_date_str = `${due_date.getUTCDate()}/${due_date.getUTCMonth()+1}/${due_date.getUTCFullYear()}`;
  const downpayment = usesRegistration ? registrationAmount : agreement.down_payment_total.value / 100.0;
  const drawdown = agreement.drawdown_payment_amount.value / 100.0;

  return (
    <span>
      Paga <FormattedNumber value={downpayment} style="currency" currency={currency} /> ahora y <FormattedNumber value={drawdown} style="currency" currency={currency} /> el {due_date_str}
    </span>
  );
};

const Pp6 = PpBase;

const Pp9 = PpBase;

const Sp1 = ({ creditAgreement, currency }) => {
  const agreement = creditAgreement[0];
  const downpayment_amount = agreement.down_payment_total.value / 100.0;
  const setup_fee = agreement.setup_fee.value / 100.0;
  const now = <FormattedNumber value={downpayment_amount + setup_fee} style="currency" currency={currency} />;
  const instalment_amount = agreement.instalment_amount.value / 100.0;
  const instalments = <FormattedNumber value={instalment_amount} style="currency" currency={currency} />;

  return <span>Divide en 3 y paga ${now} ahora y ${instalments} en cada cuota</span>;
}

Simulator.propTypes = {
  creditAgreement: PropTypes.array,
  productCode: PropTypes.string,
};

Simulator.defaultProps = {
  creditAgreement: [],
  productCode: '',
};

export default Simulator;
