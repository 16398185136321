import React from 'react';
import PropTypes from 'prop-types';

const FormSection = ({ title, children, id }) => (
  <section id={id}>
    <h2>{title}</h2>
    {children}
  </section>
);

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
};

FormSection.defaultProps = {
  id: null,
};

export default FormSection;
