import React, { Component } from 'react';
import MerchantSelect from '../MerchantSelect';

class MultiUserForm extends Component {
  constructor(props) {
    super(props)
    this.authenticityToken = props.authenticityToken
    this.merchants = props.merchants || []
  }

  setMerchant(reference) {
    this.props.merchantHasChanged(reference)
  }

  render() {
    return (
      <div className="multiuserform">
        <a href="https://docs.sequrapi.com/ref/create_update_users_in_shenzi.html" target="_blank">Instrucciones para el archivo a adjuntar</a>
        <form encType="multipart/form-data" className="col-4 mb-3 pl-0" action="/users/import" method="post">
          <input type="hidden" name="authenticity_token" value={this.authenticityToken} />
          <div className="form-group">
            <MerchantSelect merchants={this.merchants}
              onChange={e => this.setMerchant(e.target.value) }
              defaultValue={this.props.selectedMerchant.reference}
              className="mr-2 form-control"
              name="users[merchant]"
              id="users_merchant"
            />
          </div>
          <div className="form-group">
            <input accept=".csv,.xlsx,.xls" required="required" type="file" name="users[file]" id="users_file" />
          </div>
          <div className="form-group">
            <input type="submit" name="commit" value="Guardar" className="btn btn-primary" data-disable-with="Guardar Users" />
          </div>
        </form>
      </div>
    )
  }
}

export default MultiUserForm
