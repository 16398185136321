import React from 'react';
import HorizontalInput from '../HorizontalInput.jsx';
import DniImage from 'images/dni-back.png';
import NieImage from 'images/nie-back.png';

const ExtraNinInfo = props => props.requiresExtraNinInfo && <HorizontalInput
  name="order[nin_control]"
  label="NIN control"
  defaultValue={props.ninControl}
  autoComplete="off"
  info={imageForNin} />;

const imageForNin =
  <div className="container">
    <div className="row">
      <div className="col text-center">
        Por favor, introduce el número de control NIF/NIE
      </div>
    </div>
    <div className="row">
      <div className="col">
        <img className="d-block m-auto identification" src={DniImage} />
      </div>
      <div className="col">
        <img className="d-block m-auto identification" src={NieImage} />
      </div>
    </div>
  </div>;

export default ExtraNinInfo;
