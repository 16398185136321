import React from 'react';
import Simulator from '../Simulator.jsx';
import renderer from 'react-test-renderer';
import {IntlProvider} from 'react-intl';

const creditAgreements = [
  {
    'down_payment_total' : { 'string' : '3,00 €', 'value' : 300 },
    'grand_total' : { 'string' : '9,00 €', 'value' : 900 },
    'instalment_count' : 3,
    'instalment_fee' : { 'string' : '3,00 €', 'value' : 300 },
    'instalment_total' : { 'string' : '3,00 €', 'value' : 300 },
    'setup_fee': { 'string' : '1,00 €', 'value' : 100 }
  },
  {
    'down_payment_total' : { 'string' : '3,00 €', 'value' : 300 },
    'grand_total' : { 'string' : '18,00 €', 'value' : 1800 },
    'instalment_count' : 6,
    'instalment_fee' : { 'string' : '3,00 €', 'value' : 300 },
    'instalment_total' : { 'string' : '3,00 €', 'value' : 300 },
    'setup_fee': { 'string' : '0,00 €', 'value' : 0 }
  },
  {
    'down_payment_total' : { 'string' : '3,00 €', 'value' : 300 },
    'grand_total' : { 'string' : '36,00 €', 'value' : 3600 },
    'instalment_count' : 12,
    'instalment_fee' : { 'string' : '3,00 €', 'value' : 300 },
    'instalment_total' : { 'string' : '3,00 €', 'value' : 300 },
    'setup_fee': { 'string' : '0,00 €', 'value' : 0 }
  }
];

describe('Simulator', () => {
  const component = renderer.create(
    <IntlProvider locale="es">
      <Simulator
        productCode='pp3'
        creditAgreement={creditAgreements}
        setupFee={0}
        currency="EUR"
      />
    </IntlProvider>
  );

  test('matches snapshot', () => {
    let tree = component.toJSON();
    expect(tree).toMatchSnapshot();
  });
});
