import React from 'react';
import PropTypes from 'prop-types';

const HorizontalInput = props => {
  const { name, label, ...other } = props;
  const id = name.replace(']', '').replace('[', '_');
  return (
    <div className="form-group row">
      <div className="col-3">
        <label className="col-form-label" htmlFor={id}>{label}</label>
      </div>
      <div className="col">
        <div className="row">
          <div className="col">
            {props.children || <input
              className="form-control"
              id={id}
              name={name}
              placeholder={label}
              {...other}
            />}
          </div>
        </div>
        {props.info && <ExtraInfo {...props} />}
      </div>
    </div>
  );
};

const ExtraInfo = props =>
  <div className="row">
    <div className="col">
      {props.info}
    </div>
  </div>;

HorizontalInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  info: PropTypes.node,
  label: PropTypes.string.isRequired,
};

HorizontalInput.defaultProps = {
  name: '',
  type: 'text',
  info: undefined,
};

export default HorizontalInput;
