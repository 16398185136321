// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Simulator matches snapshot 1`] = `
<section
  id={null}
>
  <h2>
    Simulador
  </h2>
  <table
    className="table"
  >
    <thead>
      <tr>
        <th>
          Cuotas
        </th>
        <th>
          Comisión de apertura
        </th>
        <th>
          Coste Mensual
        </th>
        <th>
          Pagarás ahora
        </th>
        <th>
          Pagarás cada mes
        </th>
        <th>
          Coste total
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          3
        </td>
        <td>
          1,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          9,00 €
        </td>
      </tr>
      <tr>
        <td>
          6
        </td>
        <td>
          0,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          18,00 €
        </td>
      </tr>
      <tr>
        <td>
          12
        </td>
        <td>
          0,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          3,00 €
        </td>
        <td>
          36,00 €
        </td>
      </tr>
    </tbody>
  </table>
</section>
`;
