import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Loading from 'images/loading.gif';
import Tick from 'images/tick-green.png';
import Warning from 'images/exclamation-triangle-red.svg';

class State extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status || '',
    };
  }

  getPublicState() {
    Axios.get(`/solicitations/${this.props.uuid}/state`)
      .then(response => response.data)
      .then(status => this.setState(status));
  }

  componentDidMount() {
    this.getPublicState();
  }

  componentDidUpdate() {
    if (FINISHED_STATES.includes(this.state.status)) return null;
    setTimeout(() => this.getPublicState(), 10000);
  }

  render () {
    const { status } = this.state;
    return (
      <React.Fragment>
        {translate(status)}
        <img height="25px" className="ml-2" src={stateImage(status)} />
      </React.Fragment>
    );
  }
}

const stateImage = status => {
  if (SUCCESSFUL_STATES.includes(status)) return Tick;
  if (FAILED_STATES.includes(status)) return Warning;
  return Loading;
}

const SUCCESSFUL_STATES = [ 'confirmed', 'confirmed' ];
const FAILED_STATES = [ 'dead', 'denied' ];
const FINISHED_STATES = [].concat(SUCCESSFUL_STATES).concat(FAILED_STATES);
const TRANSLATIONS = {
  confirmed: 'Confirmado',
  confirmed_and_failed_notification: 'Confirmado',
  dead: 'Caducado',
  denied: 'Denegado',
  live: 'Pendiente',
};
const translate = string => TRANSLATIONS[string] || string;

State.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default State;
