import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import HorizontalInput from '../HorizontalInput.jsx';

const Address = ({ street, postalCode, city, mobilePhone, country }) => (
  <FormSection title="Dirección">
    <HorizontalInput
      name="order[address_street]"
      label="Dirección (calle, número, piso, puerta)"
      defaultValue={street}
    />
    <HorizontalInput
      name="order[address_postal_code]"
      label="Código postal"
      defaultValue={postalCode}
    />
    <HorizontalInput
      name="order[address_city]"
      label="Ciudad"
      defaultValue={city}
    />
    <HorizontalInput
      name="order[mobile_phone]"
      label="Teléfono móvil (sin prefijo)"
      defaultValue={mobilePhone}
    />
    <input type="hidden" name="order[country]" value={country} />
  </FormSection>
);

Address.propTypes = {
  street: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  mobilePhone: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  country: PropTypes.string,
  onCountryChanged: PropTypes.func 
};

Address.defaultProps = {
  street: '',
  postalCode: '',
  city: '',
  mobilePhone: '',
};

export default Address;
