import React, { Component } from 'react';
import MerchantSelect from '../MerchantSelect';
import MultiUserForm from './MultiUserForm';
import UserForm from './UserForm'
import UserList from './UserList'

class Index extends Component {
  constructor(props) {
    super(props)
    this.merchants = props.merchants;
    this.authenticityToken = props.authenticityToken;
    this.roles = props.roles;
    this.state = {
      multiUserForm: true,
      selectedMerchant: this.merchants.find(m => m.selected) || this.merchants[0]
    };
  };

  setMerchant(reference) {
    let merchant = this.merchants.find(m => m.reference == reference)
    this.setState({ selectedMerchant: merchant})
  }

  get linkMessage() {
    return this.state.multiUserForm ? "Crear un usuario" : "Importar usuarios"
  }

  handleMerchantHasChanged = (reference) => { this.setMerchant(reference) }

  switchForm = () => {
    this.setState(state => ({ multiUserForm: !state.multiUserForm }))
  }

  render() {
    return (
      <React.Fragment>
        {this.form}

        <div className="mb-3">
          <a href="#" onClick={this.switchForm}>{this.linkMessage}</a>
        </div>

        <UserList merchant={this.state.selectedMerchant} authenticityToken={this.authenticityToken}/>
      </React.Fragment>
    );
  };

  get form() {
    if (this.state.multiUserForm) {
      return <MultiUserForm authenticityToken={this.authenticityToken} merchants={this.merchants} selectedMerchant={this.state.selectedMerchant}
        merchantHasChanged={this.handleMerchantHasChanged} />
    } else {
      return <UserForm authenticityToken={this.authenticityToken} merchants={this.merchants} selectedMerchant={this.state.selectedMerchant}
        roles={this.roles} merchantHasChanged={this.handleMerchantHasChanged}
      />
    }
  }
};

export default Index;
