import React from "react";
import { useIntl } from 'react-intl';

export default function PpPaymentMethod({ pmDescription, creditAgreement }) {
  const intl = useIntl();

  return (
    <>
      <div className="description">
        {creditAgreement.map((p, k) => {
          return <div key={k} className="instalments">
            <div className="instalment-months">{p['instalment_count']} {intl.formatMessage({ id: 'instalments', defaultMessage: "cuotas"})}</div>
            <div className="instalment-price">{p['instalment_total'].string}/{intl.formatMessage({ id: 'month', defaultMessage: "mes"})}</div>
          </div>;
        })}
      </div>
      <div>{pmDescription}</div>
    </>
  );
}