import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import HorizontalInput from '../HorizontalInput.jsx';

const Vehicle = props => (
  <FormSection title="Vehículo" id="vehicle">
    <HorizontalInput
      name="order[vehicle_plaque]"
      label="Matrícula"
      defaultValue={props.vehicle_plaque} />
    <HorizontalInput
      name="order[vehicle_brand]"
      label="Marca"
      defaultValue={props.vehicle_brand} />
    <HorizontalInput
      name="order[vehicle_model]"
      label="Modelo"
      defaultValue={props.vehicle_model} />
  </FormSection>
);

Vehicle.propTypes = {
  vehicle_plaque: PropTypes.string,
  vehicle_brand: PropTypes.string,
  vehicle_model: PropTypes.string,
};

Vehicle.defaultProps = {
  vehicle_plaque: '',
  vehicle_brand: '',
  vehicle_model: '',
};

export default Vehicle;
