import React from 'react';
import PaymentMethod from './PaymentMethod';
import { useIntl } from 'react-intl';

export default function Form({ paymentOptions, destURL, orderUUID, selectedMethod, handleSelect, cancelURL, creditAgreements }) {
  
  const intl = useIntl();

  const buttonDisabled = selectedMethod === '' ? true : false;

  return (
    <form action={destURL} method="get">

      <input key="order_uuid" type="hidden" name="order_uuid" value={orderUUID} />

      {/* Looping through the payment categories and rendering them */}
      {paymentOptions.map((po, i) => {
        return (po.methods.length !== 0) &&
          <div key={i}>
            <div className="category-title">{po.title}</div>
            <div className="category-description">{po.description}</div>
            <div className="pm-container">

              {/* Looping througn the payment methods of each of the categories and rendering them */}
              {po.methods.map((pm, j) => {
                return (
                  <PaymentMethod
                    key={j}
                    pmInfo={pm}
                    paymentOption={po}
                    selectedMethod={selectedMethod}
                    onSelect={handleSelect}
                    creditAgreement={creditAgreements[selectedMethod] || []}
                  />
                );
              })}
            </div>
          </div>;
      })}

      <div key="button-container" className="button-container">
        <a className="back-link" href={cancelURL}>{intl.formatMessage({ id: 'returnToPm', defaultMessage: "Volver a métodos de pago"})}</a>
        <button
          type="submit"
          disabled={buttonDisabled}
          className="submit-button"
          style={{ backgroundColor: buttonDisabled && "#BBBBBB", cursor: buttonDisabled && "default" }}
        >{intl.formatMessage({ id: 'continue', defaultMessage: "Continuar"})}
        </button>
      </div>

    </form>
  );
}
