import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Order from './Order.jsx';
import Address from './Address.jsx';
import Merchant from './Merchant.jsx';
import Shopper from './Shopper.jsx';
import Cart from './Cart.jsx';
import PaymentMethod from './PaymentMethod.jsx';
import AvailableInstalments from './AvailableInstalments.jsx';
import Vehicle from './Vehicle.jsx';
import Simulator from '../Simulator.jsx';
import HorizontalInput from '../HorizontalInput.jsx';
import CreditAgreement from '../CreditAgreement.js';
import moment from 'moment';
import {IntlProvider} from 'react-intl';
import messages from '../messages.js';

class NewSolicitation extends React.Component {
  constructor(props) {
    super(props);
    const { params, merchants } = props;
    const merchant = this.getMerchant(params.merchant_reference) || merchants[0];
    this.state = {
      needsSetup: true,
      selectedMerchantReference: merchant.reference,
      selectedProductCode: params.product_code || merchant.product_codes[0],
      items: [],
      setupFee: params.setup_fee || '',
      availableMerchantItems: [],
      availableInstalmentCounts: [],
      order_ref_1: params.order_ref_1 || '',
      creditAgreements: {},
      creditAgreement: new CreditAgreement(merchant),
    };
    this.onPaymentMethodSelected = this.onPaymentMethodSelected.bind(this);
    this.onCartChanged = this.onCartChanged.bind(this);
    this.updateCreditAgreement = this.updateCreditAgreement.bind(this);
    this.updateCreditAgreementRemote = this.updateCreditAgreementRemote.bind(this);
    this.onSetupFeeChanged = this.onSetupFeeChanged.bind(this);
    this.onGenerateReference = this.onGenerateReference.bind(this);
  }

  onGenerateReference() {
    let ref = this.state.selectedMerchantReference.substring(0,4)
    let rand = Math.floor(Math.random() * Math.floor(10));
    let date = moment().format('YYMMDDhmmss')
    let generatedReference = `${ref}${date}${rand}`
    this.setState({order_ref_1: generatedReference})
  }

  get selectedMerchant() {
    return this.getMerchant(this.state.selectedMerchantReference);
  }

  getMerchant(reference) {
    return this.props.merchants.find(merchant => merchant.reference === reference);
  }

  async componentDidMount() {
    const availableMerchantItems = await this.loadAvailableMerchantItems(this.selectedMerchant);

    this.setState({ availableMerchantItems });
  }

  async componentDidUpdate(previousProps, previousState) {
    if (previousState.needsSetup || this.state.selectedMerchantReference != previousState.selectedMerchantReference) {
      const creditAgreement = new CreditAgreement(this.selectedMerchant);
      const availableMerchantItems = await this.loadAvailableMerchantItems(this.selectedMerchant);
      const availableInstalmentCounts = await creditAgreement.availableInstalmentCounts(this.state.selectedProductCode);

      this.setState({ availableInstalmentCounts, availableMerchantItems, creditAgreement, needsSetup: false });
    }
  }

  async loadAvailableMerchantItems(merchant) {
    const params = {
      merchant: merchant.reference,
      only_available: true,
    };
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    return Axios.get('/merchant_items/items', { params, headers, responseType: 'json' }).then(response => response.data);
  }

  async onPaymentMethodSelected(selectedProductCode) {
    const availableInstalmentCounts = await this.state.creditAgreement.availableInstalmentCounts(selectedProductCode);

    this.setState({ selectedProductCode, availableInstalmentCounts }, this.updateCreditAgreement);
  }

  onCartChanged(items) {
    this.setState({ items }, this.updateCreditAgreement);
  }

  onSetupFeeChanged(setupFee) {
    this.setState({ setupFee: setupFee || 0 });
  }

  get priceWithTax() {
    return totalWithTax(this.state.items);
  }

  get registrationAmount() {
    return totalWithTax(this.state.items.filter(item => item.type === 'registration'));
  }

  get shouldShowAvailableInstalments() {
    return this.selectedMerchant.can_select_instalments && this.state.availableInstalmentCounts.length > 0;
  }

  get shouldShowSetupFee() {
    return this.selectedMerchant.uses_setup_fee_for_pp6 && this.state.selectedProductCode === 'pp6';
  }

  get shouldShowPlaque() {
    return this.selectedMerchant.requires_plaque;
  }

  get currency() {
    return this.selectedMerchant.currency || CURRENCY_BY_COUNTRY[this.selectedMerchant.country];
  }

  updateCreditAgreement() {
    const productCode = this.state.selectedProductCode;
    const priceWithTax = this.priceWithTax;
    const registrationAmount = this.registrationAmount;
    const currency = this.currency;

    this.state.creditAgreement.computeLocal(productCode, priceWithTax, registrationAmount, currency)
      .then(creditAgreements => this.setState({ creditAgreements }))
      .catch(console.error);
  }

  updateCreditAgreementRemote() {
    const productCode = this.state.selectedProductCode;

    this.state.creditAgreement.computeRemote(productCode, this.state.items)
      .then(creditAgreements => this.setState({ creditAgreements }))
      .catch(console.error);
  }

  render () {
    const {
      params,
      merchants,
      store_refs
    } = this.props;
    const {
      selectedProductCode,
      creditAgreement,
      creditAgreements,
      setupFee,
      availableInstalmentCounts,
      availableMerchantItems,
      order_ref_1
    } = this.state;
    const selectedMerchant = this.selectedMerchant;
    const locale = LOCALE_BY_COUNTRY[selectedMerchant.country];

    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Merchant
          merchants={merchants}
          selected={selectedMerchant.reference}
          onMerchantChange={(selectedMerchantReference) => this.setState({ selectedMerchantReference })}
        />
        <Order
          reference={order_ref_1}
          storeRefs={store_refs[selectedMerchant.reference]}
          selectedStoreRef={params.store_ref}
          onGenerateReference={this.onGenerateReference}
        />
        <Address
          street={params.address_street}
          postalCode={params.address_postal_code}
          city={params.address_city}
          mobilePhone={params.mobile_phone}
          country={selectedMerchant.country}
        />
        <Shopper
          nin={params.nin}
          givenNames={params.given_names}
          surnames={params.surnames}
          email={params.email_address}
          dateOfBirth={params.date_of_birth}
          requiresExtraNinInfo={selectedMerchant.requires_extra_nin_info}
          ninControl={params.nin_control}
        />
        {this.shouldShowPlaque && <Vehicle
          vehicle_plaque={params.vehicle_plaque}
          vehicle_brand={params.vehicle_brand}
          vehicle_model={params.vehicle_model}
        />}
        <Cart
          requiresService={selectedMerchant.requires_service}
          usesRegistration={selectedMerchant.uses_registration}
          canUseNonStoredServices={selectedMerchant.can_use_non_stored_services}
          canSetEndingDate={selectedMerchant.can_set_ending_date}
          availableItems={availableMerchantItems}
          items={params.items}
          setupFee={parseFloat(setupFee || 0)}
          onCartChanged={this.onCartChanged}
          currency={this.currency}
        />
        {this.shouldShowSetupFee && <HorizontalInput label="Setup fee">
          <div className="col-5">
            <input
              type="number"
              className="form-control"
              name="order[setup_fee]"
              min={0}
              step="any"
              value={setupFee}
              placeholder="Setup fee"
              onChange={event => this.onSetupFeeChanged(Number.parseFloat(event.target.value))}
            />
          </div>
        </HorizontalInput>}
        <PaymentMethod
          productCodes={selectedMerchant.product_codes}
          selectedProductCode={selectedProductCode}
          onPaymentMethodSelected={this.onPaymentMethodSelected}
        />
        {this.shouldShowAvailableInstalments && <AvailableInstalments
          availableInstalmentCounts={availableInstalmentCounts}
          selectedInstalmentCounts={params.available_instalments_count}
        />}
        <Simulator
          creditAgreement={creditAgreements[selectedProductCode]}
          usesRegistration={selectedMerchant.uses_registration}
          usesFullFeesInAdvance={selectedMerchant.uses_full_fees_in_advance}
          registrationAmount={this.registrationAmount}
          setupFee={setupFee}
          productCode={selectedProductCode}
          currency={this.currency}
        />
        {creditAgreement.usesCustomCreditAgreements(selectedProductCode) && <input
          type="button"
          className="form-control btn btn-outline-primary"
          onClick={this.updateCreditAgreementRemote}
          value="Recalcular"
        />}
        <div className="row">
          <div className="col">
            <input
              type="submit"
              value="Enviar"
              className="btn btn-primary"
            />
          </div>
        </div>
        <input
          name="order[echo_notify_url]"
          value={params.echo_notify_url}
          hidden
        />
      </IntlProvider>
    );
  }
}

const totalWithTax = items => items
  .filter(item => item.price_with_tax && item.quantity)
  .reduce((sum, item) => item.price_with_tax*item.quantity + sum, 0);

const LOCALE_BY_COUNTRY = {
  "ES": "es-ES",
  "CO": "es-CO",
  "FR": "fr-FR",
  "IT": "it-IT",
  "PE": "es-PE",
  "PT": "pt-PT",
};

const CURRENCY_BY_COUNTRY = {
  "ES": "EUR",
  "CO": "COP",
  "FR": "EUR",
  "IT": "EUR",
  "PE": "PEN",
  "PT": "EUR",
};

NewSolicitation.propTypes = {
  params: PropTypes.object.isRequired,
  merchants: PropTypes.array.isRequired,
  store_refs: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

NewSolicitation.defaultProps = {
  params: {},
  merchants: [],
  storeRefs: {},
};

export default NewSolicitation;
