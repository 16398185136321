import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import HorizontalInput from '../HorizontalInput.jsx';
import MerchantSelect from '../MerchantSelect';

const Merchants = props => (props.merchants.length > 1 ? <MultipleMerchants {...props} /> : <OneMerchant merchant={props.merchants[0]} />);

const MultipleMerchants = ({ merchants, onMerchantChange, selected }) => (
  <FormSection title="Merchant">
    <HorizontalInput name="order[merchant_reference]" label="Merchant reference">
      <MerchantSelect
        merchants={merchants}
        className="form-control"
        name="order[merchant_reference]"
        value={selected}
        id="order_merchant_reference"
        onChange={event => onMerchantChange(event.target.value)}
      />
    </HorizontalInput>
  </FormSection>
);

const OneMerchant = ({ merchant }) => (<input name="order[merchant_reference]" id="order_merchant_reference" defaultValue={merchant.reference} hidden />);

Merchants.propTypes = {
  merchants: PropTypes.array.isRequired,
};

export default Merchants;
