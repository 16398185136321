import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../FormSection.jsx';
import ItemRow from './ItemRow.jsx';
import {FormattedNumber} from 'react-intl';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    const { items } = props;
    this.state = { items };
    this.onTypeChanged = this.onTypeChanged.bind(this);
    this.onPriceChanged = this.onPriceChanged.bind(this);
    this.onQuantiyChanged = this.onQuantiyChanged.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);
    this.addItem = this.addItem.bind(this);
    this.onItemRemoved = this.onItemRemoved.bind(this);
  }

  componentDidMount() {
    this.prepareItems();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.availableItems === this.props.availableItems) return null;
    this.prepareItems();
  }

  prepareItems() {
    const { requiresService, usesRegistration } = this.props;
    const { items } = this.state;

    const tmpItems = items.length > 0 ? items : defaultItems();
    const newItems = tmpItems.filter(i => ![ 'service', 'registration' ].includes(i.type));
    if (requiresService) newItems.push(this.serviceItem());
    if (usesRegistration) newItems.push(this.registrationItem());
    newItems.forEach(item => item.key = item.key ? item.key : newKey());
    this.setState({ items: newItems });
    this.props.onCartChanged(newItems);
  }

  serviceItem() {
    const { requiresService, items } = this.props;
    if (!requiresService) return null;
    const service = items.find(item => item.type === 'service') || [];
    return {
      ...service,
      quantity: 1,
      type: 'service',
    };
  }

  registrationItem() {
    const { usesRegistration, items } = this.props;
    if (!usesRegistration) return null;
    const registration = items.find(item => item.type === 'registration');
    return {
      ...registration,
      quantity: 1,
      type: 'registration',
    };
  }

  addItem(event) {
    event.preventDefault();
    const newItems = this.state.items.map(x => x).concat(defaultItem());
    this.setState({ items: newItems });
    this.props.onCartChanged(newItems);
  }

  onItemRemoved(item) {
    const newItems = this.state.items.filter(x => x.key !== item.key);
    this.setState({ items: newItems });
    this.props.onCartChanged(newItems);
  }

  updateItem(affectedItem, attributes) {
    const items = this.state.items.map(item => {
      if (item !== affectedItem) return item;
      const newItem = { ...item, ...attributes };
      if (shouldChangeSign(newItem)) newItem.price_with_tax = -newItem.price_with_tax;
      if (readOnlyQuantity(newItem)) newItem.quantity = 1;
      return newItem;
    });
    this.setState({ items });
    this.props.onCartChanged(items);
  }

  onTypeChanged(item, type) {
    this.updateItem(item, { type });
  }

  onPriceChanged(item, price_with_tax) {
    this.updateItem(item, { price_with_tax });
  }

  onQuantiyChanged(item, quantity) {
    this.updateItem(item, { quantity });
  }

  onDateChanged(item, ends_on) {
    this.updateItem(item, { ends_on });
  }

  onNameChanged(item, name) {
    const { availableItems } = this.props;
    const dataItem = availableItems.find(item => item.reference === name);
    this.updateItem(item, dataItem || { name });
  }

  total() {
    const { items } = this.state;
    return totalWithTax(items) + this.props.setupFee;
  }

  get sortedItems() {
    const { items } = this.state;
    const weights = {
      service: 2,
      registration: 1,
    };
    return items.sort((a, b) => (weights[b.type] || 0) - (weights[a.type] || 0));
  }

  render() {
    const { availableItems, requiresService, canUseNonStoredServices, canSetEndingDate, currency } = this.props;
    const rows = this.sortedItems.map(item => {
      const autocompleted = (requiresService && item.type === 'service') || (!requiresService && item.type === 'product');
      const autocompleteList = autocompleted ? 'available_items' : '';
      return (<ItemRow
        key={item.key}
        item={item}
        onTypeChanged={this.onTypeChanged}
        onPriceChanged={this.onPriceChanged}
        onQuantiyChanged={this.onQuantiyChanged}
        onDateChanged={this.onDateChanged}
        onNameChanged={this.onNameChanged}
        onItemRemoved={this.onItemRemoved}
        autocompleteList={autocompleteList}
        readOnlyQuantity={readOnlyQuantity(item)}
        readOnlyType={readOnlyType(item)}
        readOnlyPrice={!canUseNonStoredServices && readOnlyPrice(item)}
        canUseNonStoredServices={canUseNonStoredServices}
        canSetEndingDate={canSetEndingDate}
        canRemove={canRemove(item)}
        currency={currency}
      />);
    });
    return (
      <FormSection title="Carrito">
        {rows}
        <div className="row">
          <div className="form-group col">
            <input
              type="button"
              className="form-control btn btn-outline-primary"
              onClick={e => this.addItem(e)}
              value="&#x2b;"
            />
          </div>
          <div className="col-2">
          </div>
        </div>
        <div className="row">
          <div className="col text-right">
            <h4><FormattedNumber value={this.total()} style="currency" currency={currency} /></h4>
          </div>
        </div>
        <datalist id="available_items">
          {itemOptions(availableItems)}
        </datalist>
      </FormSection>
    );
  }
}

const defaultItem = () => ({ quantity: 1, type: 'product', key: newKey() });
const defaultItems = () => (new Array(5)).fill(0).map(() => (defaultItem()));

const newKey = () => Math.random().toString(36);

const itemOptions = items => items.map(item => <option key={item.reference} value={item.reference}>{item.name}</option>);

const readOnlyQuantity = item => [ 'service', 'discount', 'registration', 'handling' ].includes(item.type);
const readOnlyType = item => [ 'service', 'registration' ].includes(item.type);
const readOnlyPrice = item => [ 'service' ].includes(item.type);
const canRemove = item => ![ 'service', 'registration' ].includes(item.type);

const shouldChangeSign = item => (item.type === 'discount' && item.price_with_tax > 0) || (item.type !== 'discount' && item.price_with_tax < 0);

const totalWithTax = items => items
  .filter(item => item.price_with_tax && item.quantity)
  .reduce((sum, item) => item.price_with_tax*item.quantity + sum, 0);

Cart.propTypes = {
  requiresService: PropTypes.bool,
  usesRegistration: PropTypes.bool,
  items: PropTypes.array,
  setupFee: PropTypes.number,
  onCartChanged: PropTypes.func.isRequired,
  availableItems: PropTypes.array,
  setupFee: PropTypes.number,
};

Cart.defaultProps = {
  requiresService: false,
  usesRegistration: false,
  items: [],
  currency: "EUR",
  availableItems: [],
  setupFee: 0,
};

export default Cart;
